<template>
  <div>
    <h3>无障碍声明</h3>
    <p class="p1">
      兰州市残疾人就业创业网络服务平台注重以人为本，倡导和实践信息无障碍理念，遵循国际标准，集合先进技术，为视力残疾人、认知能力有障碍人士和老年人提供获取网上信息的渠道。
      全网站遵循国际标准《WCAG2.0》及国内标准《网站设计无障碍技术要求》进行无障碍网页设计，适用于盲用读屏软件。
    </p>
    <ul>
      <li>
        <p>1、全网站页面可用键盘操作，不限于鼠标。</p>
      </li>
      <li>
        <p>2、网页图片均标示文字说明。</p>
      </li>
      <li>
        <p>3、页面标题、网页链接均添加提示文字。</p>
      </li>
      <li>
        <p>4、对于网页色调，框架、语言等提供了相应的改造办法。</p>
      </li>
      <li>
        <p>5、快捷键使用说明：</p>
      </li>
      <li>
        <h4>无障碍帮助：ctrl+alt+0</h4>
      </li>
      <li>
        <h4>1、头 部： ctrl+alt+1</h4>
      </li>
      <li>
        <h4>
          2、左侧内容区域：ctrl+alt+2
          <span>注：部分页面可能因为没有左侧区域所以alt+2在某些页面中无法正常使用</span>
        </h4>
      </li>
      <li>
        <h4>3、内容区域： ctrl+alt+3</h4>
      </li>
      <li>
        <h4>4、底部区域： ctrl+alt+4</h4>
      </li>
      <li>
        <h4>
          5、栏目快捷跳转：
          <span>注：在进行栏目快捷跳转前需用alt+3将焦点跳入内容区域</span>
        </h4>
      </li>
      <li>
        <h4>6、栏目正向快捷跳转：ctrl+alt+"+"</h4>
      </li>
      <li>
        <h4>7、栏目逆向快捷跳转：ctrl+alt+"-"</h4>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>


<style lang="less" scoped>
@import "~assets/css/total.less";
</style>
